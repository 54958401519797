import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { getClientSpecificTranslation } from '../apis';
import { getAPIToken } from '../apis/cancelTokens';
import {
  selectIsGPXClient,
  selectLocale,
  selectPaymentFlowComplete,
  selectSellerId,
  selectToken,
} from '../store/slices/session/sessionSlice';
import { translationClient } from '../utils/apiClient';

const I18nService = {
  I18N_URI: '/i18n-service/i18n/load',
  I18N_CACHE_VERSION_URI: '/i18n-service/i18n/cacheVersion',
  I18N_VERTICAL_NAME: 'GPFS_PAYMENT_MODULE',
  I18N_INDUSTRY_DEFAULT_TEMPLATE: 'DEFAULT',
  I18N_INDUSTRY_CORPORATE_TEMPLATE: 'CORPORATE',
  I18N_VERSION: 'I18N_VERSION_',
  I18N_CONTENT: 'I18N_CONTENT_',
};

function I18nDataProvider({ children }) {
  const [messages, setMessages] = useState({});
  const [translationLoaded, setTranslationLoaded] = useState(false);
  const [, setCacheVersion] = useState('');
  const isGPXClient = useSelector(selectIsGPXClient);
  const token = useSelector(selectToken);
  const paymentFlowComplete = useSelector(selectPaymentFlowComplete);
  const locale = useSelector(selectLocale);
  const clientId = useSelector(selectSellerId);

  useEffect(() => {
    if (locale) {
      loadTranslations(locale).then((translations) => {
        if (Object.keys(translations).length > 0) {
          setMessages(translations);
          setTranslationLoaded(true);
        }
      });
    }
  }, [locale]);

  useEffect(() => {
    if (isGPXClient && locale) {
      getCorporateTemplate();
    }
  }, [isGPXClient, locale]);

  useEffect(() => {
    if (translationLoaded && token && !paymentFlowComplete) {
      getClientSpecificTranslations();
    }
  }, [token, paymentFlowComplete, translationLoaded, clientId]);

  async function getClientSpecificTranslations() {
    if (!clientId) {
      return;
    }
    const { status, data } = await getClientSpecificTranslation(locale, clientId);
    if (status === 200) {
      setMessages((prevState) => ({
        ...prevState,
        ...data.elements,
      }));
    }
  }

  async function getCorporateTemplate() {
    const result = await getTranslations(locale, 0, I18nService.I18N_INDUSTRY_CORPORATE_TEMPLATE);
    setMessages((prevState) => ({
      ...prevState,
      ...result.data.elements,
    }));
  }

  function loadTranslations(locale) {
    return Promise.all([loadCacheVersion(), loadTranslationsFromCache(locale)]).then(
      ([cacheVersion, cachedTranslations]) => {
        setCacheVersion(cacheVersion);
        return getTranslations(locale, cacheVersion, I18nService.I18N_INDUSTRY_DEFAULT_TEMPLATE)
          .then((translations) => {
            if (translations) {
              setTranslationsInCache(locale, translations);
              return translations.data.elements;
            }
            return cachedTranslations || {};
          })
          .catch(() => cachedTranslations || {});
      },
    );
  }

  function loadCacheVersion() {
    return translationClient
      .get(`${I18nService.I18N_CACHE_VERSION_URI}/${I18nService.I18N_VERTICAL_NAME}`, {
        cancelToken: getAPIToken(),
      })
      .then((res) => (res.data.data['version'] ? res.data.data['version'] : res.data.data))
      .catch(() => '');
  }

  function loadTranslationsFromCache(locale) {
    const version = localStorage.getItem(I18nService.I18N_VERSION + locale);
    if (localStorage.getItem(I18nService.I18N_VERSION + locale) === version) {
      return Promise.resolve(JSON.parse(localStorage.getItem(I18nService.I18N_CONTENT + locale)));
    }
    return Promise.resolve({});
  }

  function setTranslationsInCache(locale, translations) {
    localStorage.setItem(
      I18nService.I18N_VERSION + locale,
      translations.data.i18nVersion.toString(),
    );
    localStorage.setItem(
      I18nService.I18N_CONTENT + locale,
      JSON.stringify(translations.data.elements),
    );
  }

  function getTranslations(locale, cacheVersion, template) {
    const version = localStorage.getItem(I18nService.I18N_VERSION + locale);
    if (version == cacheVersion) {
      return Promise.resolve(null);
    }
    return translationClient
      .get(`${I18nService.I18N_URI}/${I18nService.I18N_VERTICAL_NAME}/${template}/${locale}`, {
        cancelToken: getAPIToken(),
      })
      .then(({ status, data }) => {
        if (status === 200) {
          return data;
        }
        return { i18nVersion: 0, elements: {} };
      })
      .catch(() => ({ i18nVersion: 0, elements: {} }));
  }

  function logTranslationMissing() {
    if (process.env.NODE_ENV == 'development') {
      // console.log(e);
    }
  }

  return (
    translationLoaded && (
      <IntlProvider
        locale={locale.replace('_', '-')}
        messages={messages}
        defaultLocale={'en'}
        onError={logTranslationMissing}
      >
        {children}
      </IntlProvider>
    )
  );
}

export default I18nDataProvider;
