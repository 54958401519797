import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { captureServices, getServiceDetails } from '../../apis';
import { CountrySelect } from '../../components/CountryDropDown';
import { Nf, RawHtmlContent } from '../../components/Format';
import { Translate } from '../../i18n/translate';
import { showModalWithFunctions } from '../../store/slices/modal/actions';
import {
  ROUTE_MAPPING,
  hidePaymentSummaryAction,
  navigate,
} from '../../store/slices/router/routerSlice';
import {
  selectCatalog,
  selectData,
  selectHideServiceCatalog,
  selectLoading,
  selectPayerCountry,
  selectSsoRequest,
  selectToken,
  setCatalog,
  setData,
  setError,
  setHomeAmount,
  setHomeCurrency,
  setIsGPXClient,
  setPayerCountry,
} from '../../store/slices/session/sessionSlice';
import Catalog from './Catalog';
import './serviceCatalog.scss';

const CountryNotListedMessage = () => (
  <RawHtmlContent htmlContent={'JS_COUNTRY_NOT_FOUND'} requireTranslate={true} />
);

const ServiceCatalog = () => {
  const token = useSelector(selectToken);
  const ssoRequest = useSelector(selectSsoRequest);
  const selectedCatalogItems = useSelector(selectCatalog);
  const selectedPayerCountry = useSelector(selectPayerCountry);
  const hideServiceCatalog = useSelector(selectHideServiceCatalog);
  const session = useSelector(selectData);
  const dispatch = useDispatch();
  const isAPIInProgress = useSelector(selectLoading);

  const [country, setCountry] = useState('');
  const [catalogItems, setCatalogItems] = useState([]);
  const [debtorGroupOptions, setDebtorGroupOptions] = useState();
  const [debtorPortalGroup, setDebtorPortalGroup] = useState();

  useEffect(() => {
    if (session && session.sso) {
      dispatch(hidePaymentSummaryAction());
    }
  }, [session]);

  useEffect(() => {
    if (session && session.sso && !hideServiceCatalog) {
      if (token && !selectedCatalogItems) {
        fetchData();
      }
    } else {
      if (session && !session.sso) {
        const serviceItems = session.serviceItemViewList?.serviceItemViews
          ? [...session.serviceItemViewList.serviceItemViews].sort((a, b) => {
              return getServiceName(a).localeCompare(getServiceName(b));
            })
          : [];
        const isGpx = session.sellerInfo.sellerIndustry != 'EDUCATION';
        dispatch(setIsGPXClient(isGpx));
        const clonedServiceItems = [...serviceItems.map((item) => ({ ...item }))];
        clonedServiceItems.forEach((s) => {
          if (serviceItems.length == 1) {
            s.included = true;
          }
          if (hasMinError(s)) {
            s.hasMinError = true;
          }
          if (hasMaxError(s)) {
            s.hasMaxError = true;
          }
        });
        setCatalogItems(clonedServiceItems);
      }
    }
  }, [token, selectedCatalogItems]);

  useEffect(() => {
    if (selectedCatalogItems) {
      setCatalogItems(selectedCatalogItems);
    }
  }, [selectedCatalogItems]);

  useEffect(() => {
    if (session && !session.sso) {
      const debtorGroups = _(catalogItems)
        .map((s) => s.debtorGroup)
        .uniq()
        .filter((group) => group !== 'All')
        .map((group) => ({
          value: group,
          label: getDebtorPortalLabel(group),
        }))
        .value();
      const debtorGroupOptions = _.find(
        session.customFieldList,
        (item) => item.name === 'debtorGroup',
      );
      const dtGrpOptionsOrderedDisplay = [];
      if (debtorGroupOptions) {
        debtorGroupOptions.values.forEach((value) => {
          var findDtGrp = _.find(debtorGroups, (item) => item.value === value.value);
          if (findDtGrp) {
            dtGrpOptionsOrderedDisplay.push(findDtGrp);
          }
        });

        debtorGroups.forEach((value) => {
          var notExtraDtGrp = _.find(
            dtGrpOptionsOrderedDisplay,
            (item) => item.value === value.value,
          );
          if (!notExtraDtGrp) {
            dtGrpOptionsOrderedDisplay.push(value);
          }
        });
      }

      setDebtorGroupOptions(debtorGroupOptions);

      if (debtorGroups.length <= 0) {
        setDebtorPortalGroup('All');
      }
    } else {
      setDebtorPortalGroup('All');
    }
  }, [session]);

  const hasMinError = (service) => {
    let minError = false;
    if (service.minAmount != null) {
      minError = service.amountOwing < service.minAmount;
    }
    return minError;
  };

  const hasMaxError = (service) => {
    let maxError = false;
    if (service.maxAmount != null) {
      maxError = service.amountOwing > service.maxAmount;
    }
    return maxError;
  };

  const fetchData = async () => {
    try {
      let apiResponse = await getServiceDetails(ssoRequest);
      if (!apiResponse.success) {
        return;
      }
      const response = apiResponse.data;
      if (response.error) {
        dispatch(setError({ error: response.error }));
        return;
      }

      const serviceItems = response.data.serviceItemViewList?.serviceItemViews
        ? [...response.data.serviceItemViewList.serviceItemViews].sort((a, b) => {
            return getServiceName(a).localeCompare(getServiceName(b));
          })
        : [];
      const isGpx = response.data.sellerInfo.sellerIndustry != 'EDUCATION';
      dispatch(setIsGPXClient(isGpx));
      serviceItems.forEach((s) => {
        s.included = true;
        if (hasMinError(s)) {
          s.hasMinError = true;
        }
        if (hasMaxError(s)) {
          s.hasMaxError = true;
        }
      });
      setCatalogItems(serviceItems);
      if (response.data.payerCountry) {
        setCountry(response.data.payerCountry);
        dispatch(setPayerCountry(response.data.payerCountry));
      }
      dispatch(setData(response.data));
    } catch (error) {
      console.error(`Seller API failed ${error.message}`);
    }
  };

  const getQuote = async () => {
    const data = {
      country: country,
      debtorGroup: debtorPortalGroup,
      services: catalogItems
        .filter((c) => c.mandatory || c.included)
        .map((item) => {
          return {
            id: item.id,
            pledgedAmount: item.amountOwing,
            notes: item.notes,
            providerClientId: item.providerClientId,
            priority: item.priority,
          };
        }),
    };
    dispatch(setHomeAmount(getTotalAmount()));
    dispatch(setHomeCurrency(session.sellerInfo?.homeCurrency));
    const apiResponse = await captureServices(data);
    dispatch(setData(apiResponse.data));
    if (apiResponse.success) {
      dispatch(setCatalog(catalogItems));
      dispatch(navigate({ route: ROUTE_MAPPING.PAYMENT_METHOD }));
    }
  };

  const getServiceName = (service) => {
    return (
      (_.get(service, 'displayServiceIdentifierFlag') && _.get(service, 'serviceIdentifier')
        ? _.get(service, 'serviceIdentifier') + '-'
        : '') + _.get(service, 'serviceName')
    );
  };

  if (_.isEmpty(session) || hideServiceCatalog) {
    return <></>;
  }

  const sortedCountries = [...session.sellerInfo.payerFeeCountries].sort((a, b) => {
    return a.localeCompare(b);
  });

  const ignorableWubsSettlementFee = (s) => {
    return !(s.wubsSettlementFee && s.amountOwing <= 0);
  };

  const showModalForCountryNotFound = () => {
    dispatch(
      showModalWithFunctions({
        title: 'Country not listed',
        body: <CountryNotListedMessage />,
        yesLabel: 'Ok',
        icon: 'icons/featured_icon.svg',
      }),
    );
  };

  const showServiceItem = (s) => {
    let debtorGroup = s.debtorGroup;
    return (
      ignorableWubsSettlementFee(s) &&
      (debtorGroup === 'All' || debtorPortalGroup === 'All' || debtorGroup === debtorPortalGroup)
    );
  };
  const serviceCount = _.filter(catalogItems, (item) => showServiceItem(item)).length;

  const getDebtorPortalLabel = (groupValue) => {
    if (groupValue === 'All') {
      return 'JS_debtorGroup_All';
    }
    return (
      _.chain(debtorGroupOptions)
        .get('values')
        .find((group) => group.value === groupValue)
        .get('label')
        .value() || groupValue
    );
  };

  const updateProperty = (id, key, val) => {
    setCatalogItems((prevItems) =>
      prevItems.map((item) => (item.id === id ? { ...item, [key]: val } : item)),
    );
  };

  const getTotalAmount = () =>
    catalogItems.reduce((total, c) => {
      total += c.mandatory || c.included ? parseFloat(c.amountOwing || 0) : 0;
      return total;
    }, 0);

  const quoteDisabled = () => {
    return (
      !country ||
      getTotalAmount() <= 0 ||
      !!catalogItems.find((c) => c.hasMinError || c.hasMaxError)
    );
  };

  return (
    <>
      <div className='container py-5 max-w-[390px]'>
        <InputLabel className='mb-1'>
          <Translate value='JS_payingFeesFrom' />
        </InputLabel>
        <FormControl fullWidth>
          <CountrySelect
            countries={sortedCountries}
            setCountry={setCountry}
            value={selectedPayerCountry}
          />
        </FormControl>

        <Tooltip title={<Translate value='JS_countryNotListedContents' />} disableFocusListener>
          <a
            className='text-accent text-center mt-4 cursor-pointer block'
            href='#'
            onClick={showModalForCountryNotFound}
          >
            <Translate value='JS_countryNotListed' />
          </a>
        </Tooltip>

        <p className='mt-4 mb-1'>
          <span className='text-base'>
            <Translate value='JS_servicesBreakdown' />
          </span>
          <Tooltip title={<Translate value='JS_ServiceBreakdown' />} disableFocusListener>
            <InfoOutlinedIcon className='text-accent ml-1' />
          </Tooltip>
        </p>

        {catalogItems.map((item, index) => (
          <div key={index} className='mb-5'>
            {showServiceItem(item) && (
              <Catalog
                service={catalogItems[index]}
                serviceCount={serviceCount}
                homeCurrency={session.sellerInfo.homeCurrency}
                updateProperty={updateProperty}
              />
            )}
          </div>
        ))}

        <p className='border-b-lightGray border-b text-base	'>
          <span>
            <Translate value='JS_total' /> ({session.sellerInfo.homeCurrency})
          </span>
          <span className='float-right font-mono'>{Nf(getTotalAmount())}</span>
        </p>

        <div className='mt-5 flex justify-end'>
          <Button
            className='text-14 rounded-full pt-[14px] pb-[14px] pl-[24px] pr-[24px]'
            variant='contained'
            disabled={quoteDisabled() || isAPIInProgress}
            onClick={getQuote}
          >
            <Translate value='JS_getQuote' />
          </Button>
        </div>
      </div>
    </>
  );
};
export default ServiceCatalog;
