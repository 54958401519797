const Tracker = ({ themeObject, scriptLoaded, token, locale }) => {
  return (
    <div>
      {scriptLoaded && (
        <convera-payment-tracking
          data-locale={locale}
          data-theme={JSON.stringify(themeObject)}
          data-reference-id=''
          data-eps-token={token}
        ></convera-payment-tracking>
      )}
    </div>
  );
};

export default Tracker;
