import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import store from '../../store';
import { setPayerCountry } from '../../store/slices/session/sessionSlice';

export const CountrySelect = ({
  id = 'country-select',
  countries,
  setCountry,
  disabled = false,
  value,
  error,
  setPayerCountryInStore = true,
}) => {
  const [translateCountries, setTranslateCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    if (countries) {
      let _countries = countries.map((c) => {
        const obj = { code: c, name: intl.formatMessage({ id: c }) };
        if (c == value) {
          setSelectedCountry(obj);
          setCountry(c);
        }
        return obj;
      });
      _countries = _countries.sort((c1, c2) => (c1.name > c2.name ? 1 : -1));
      setTranslateCountries(_countries);
    }

    if (!value) {
      setSelectedCountry(null);
    }
  }, [countries, value]);

  const filterOptions = (options, { inputValue }) => {
    const inputValueLowerCase = inputValue.toLowerCase();
    const list = options.filter((option) => {
      const splittedWords = option.name.split(' ');
      return splittedWords.find((w) => w.toLowerCase().startsWith(inputValueLowerCase));
    });
    return list.length
      ? list
      : options.filter((option) => option.name.toLowerCase().startsWith(inputValueLowerCase));
  };

  return (
    <Autocomplete
      id={id}
      options={translateCountries}
      autoHighlight
      getOptionLabel={(option) => option.name}
      value={selectedCountry}
      disabled={disabled}
      filterOptions={filterOptions}
      onChange={(_, v) => {
        if (v) {
          if (setPayerCountryInStore) {
            store.dispatch(setPayerCountry(v.code));
          }
          setSelectedCountry(v);
          setCountry(v.code);
        } else {
          setSelectedCountry(null);
          setCountry(null);
          if (setPayerCountryInStore) {
            store.dispatch(setPayerCountry(null));
          }
        }
      }}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      renderOption={(props, option) => {
        const flagClassName = `gpfs-flag ${option?.code.toLowerCase()}`;
        return (
          <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <i key={option.code} className={flagClassName} value={option}></i>
            {option.name}
          </Box>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={error}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password',
              placeholder: 'Select Country',
              startAdornment: selectedCountry && (
                <i className={`gpfs-flag ${selectedCountry.code.toLowerCase()}`}></i>
              ),
            }}
          />
        );
      }}
    />
  );
};
