import { Button, FormControl, FormHelperText, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInstitutions, getLegalEntitityCountries, getSellerInfo } from '../../apis';
import { CountrySelect } from '../../components/CountryDropDown';
import { RawHtmlContent } from '../../components/Format';
import InputWithLabel from '../../components/InputWithLabel';
import { InstitutionSelect } from '../../components/InstitutionDropDown';
import { Translate } from '../../i18n/translate';
import { showModalWithFunctions } from '../../store/slices/modal/actions';
import { navigate, ROUTE_MAPPING } from '../../store/slices/router/routerSlice';
import { setData, setSellerId } from '../../store/slices/session/sessionSlice';

const Landing = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    institutionCountry: '',
    institutionName: '',
  });
  const [errors, setErrors] = useState({
    institutionCountry: false,
    institutionName: false,
  });
  const [legalCountries, setLegalCountries] = useState([]);
  const [institutions, setInstitutions] = useState([]);

  useEffect(() => {
    fetchLegalEntityCountries();
  }, []);

  const UniversityNotListed = () => (
    <RawHtmlContent htmlContent={'JS_universityNotListedContents'} requireTranslate={true} />
  );

  const fetchLegalEntityCountries = async () => {
    let {
      data: { legalEntityCountries },
    } = await getLegalEntitityCountries();
    if (legalEntityCountries) {
      setLegalCountries(legalEntityCountries);
      setErrors({});
      setFormData({});
    }
  };

  const fetchInstituitions = async (country) => {
    try {
      const response = await getInstitutions(country);
      if (response?.data?.sellers && response.data.sellers.length > 0) {
        setInstitutions(response.data.sellers);
      } else {
        console.warn('No sellers found.');
      }
    } catch (error) {
      setInstitutions([]);
    }
  };

  const onCountryChange = (value) => {
    fetchInstituitions(value);
    handleChange('institutionCountry', value);
  };

  const handleChange = (key, value) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
  };

  const navigateToServiceCatalog = async () => {
    dispatch(setSellerId(formData.institutionName));
    const response = await getSellerInfo(formData.institutionName);
    if (response.success) {
      const { data } = response;
      dispatch(setData(data));
    }
    dispatch(navigate({ route: ROUTE_MAPPING.SERVICE_CATALOG }));
  };

  const showModalForUniNotFound = () => {
    dispatch(
      showModalWithFunctions({
        title: 'University not listed',
        body: <UniversityNotListed />,
        yesLabel: 'Ok',
        icon: 'icons/featured_icon.svg',
      }),
    );
  };

  return (
    <div className='container py-5 max-w-[390px]'>
      <div>
        {/* Select studying in country */}
        <InputWithLabel label='JS_studyingIn'>
          <CountrySelect
            name='institutionCountry'
            countries={legalCountries}
            setCountry={(v) => {
              onCountryChange(v);
            }}
            value={formData.institutionCountry}
            error={!!errors.institutionCountry}
            setPayerCountryInStore={false}
          />
          {errors.institutionCountry && (
            <FormHelperText error>{errors.institutionCountry}</FormHelperText>
          )}
        </InputWithLabel>
      </div>

      {/* Select instiuition */}
      <FormControl fullWidth error={errors.institutionName}>
        <label className={`text-sm mb-[5px] block ${errors.institutionName ? 'text-error' : ''}`}>
          <Translate value='JS_institution' />
        </label>
        <InstitutionSelect
          institutions={institutions}
          disabled={!formData.institutionCountry}
          //   placeholder={getTransla('PT_INST_ENTER')}
          setInstitution={(value) => {
            handleChange('institutionName', value);
          }}
        />
        {errors.institutionName && (
          <p style={{ color: 'red' }}>
            <Translate value='PT_INST_NAME_REQUIRED' />
          </p>
        )}
      </FormControl>

      <Tooltip title={<Translate value='JS_universityNotListedContents' />} disableFocusListener>
        <a
          className='text-accent text-center mt-4 cursor-pointer block'
          onClick={showModalForUniNotFound}
        >
          <Translate value='JS_universityNotListed' />
        </a>
      </Tooltip>

      <div className='mt-5 flex justify-end'>
        <Button
          className='text-14 rounded-full pt-[14px] pb-[14px] pl-[24px] pr-[24px]'
          variant='contained'
          disabled={!formData.institutionCountry || !formData.institutionName}
          onClick={navigateToServiceCatalog}
        >
          <Translate value='JS_next' />
        </Button>
      </div>
    </div>
  );
};

export default Landing;
